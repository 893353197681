import { Cloud$Market } from '../types/cloudEnums';
import { Otovo$Locale } from '../types/otovoweb';

const getDomainFromHost = (host: string) => {
  return host
    .split('.')
    .filter((e) => e)
    .reverse()[0]
    .split(':')[0];
};

export const getClientSideLanguageFromDomain = (): any | null => {
  if (!__isBrowser__) {
    return null;
  }

  const domain = getDomainFromHost(window.location.hostname);

  const locales = {
    no: 'nb',
    se: 'sv',
    es: 'es',
    fr: 'fr',
  };

  return locales[domain] || null;
};

export const setLocaleCookie = (locale: string) => {
  const hasTrackingConsent = document.cookie.includes('hasTrackingConsent');
  if (hasTrackingConsent) {
    document.cookie = `NEXT_LOCALE=${locale}; maxage=${
      1000 * 60 * 60 * 24 * 7
    }; path=/`;
  }
};

export function localeToMarket(locale: Otovo$Locale) {
  return locale.split('-')[1] as Cloud$Market;
}
